import { ItemCategoryTypes, LabourTimeTypes, PaintGroupTypes, QuoteItemRevTypes, QuotingMethodTypes, PartTypes, QuoteItemStatusTypes } from '@/enums'
import { QuoteItemModel } from '@/classes/viewmodels'
import _isEmpty from 'lodash/isEmpty'
import _merge from 'lodash/merge'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import _cloneDeep from 'lodash/cloneDeep'
import Guid from '@/components/Guid'
import { EventHubTypes } from '@/enums'
import QuoteService from '../QuoteService'

export default {
  mixins: [],
  computed: {
    isNtar() {
      return (
        this.innerValue.labourType === LabourTimeTypes.NTAR ||
        this.innerValue.labourType === LabourTimeTypes.LTAR ||
        this.innerValue.labourType === LabourTimeTypes.eMTA
      )
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    labourTimeTypes() {
      return LabourTimeTypes
    }
  },
  data() {
    return {
      lastAddedItem: null
    }
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.lastAddedItem, (item) => {
      this.lastAddedItem = item
    })
  },
  methods: {
    isBlankItem(item) {
      switch (item.itemType) {
        case ItemCategoryTypes.FIBER:
        case ItemCategoryTypes.CRUSH:
        case ItemCategoryTypes.CD:
        case ItemCategoryTypes.MECH:
          return item.itemDesc == '' && item.hourValue == 0 && item.dollarValue == 0
        case ItemCategoryTypes.RR:
        case ItemCategoryTypes.RWA:
        case ItemCategoryTypes.REP:
        case ItemCategoryTypes.PAINT:
          return item.itemDesc == '' && item.hourValue == 0
        case ItemCategoryTypes.PART:
          return item.itemDesc == '' && item.partNo == 0 && item.itemQuantity == 1
        case ItemCategoryTypes.OPG:
          return item.itemDesc == '' && item.hourValue == 0
        case ItemCategoryTypes.MISC:
          return item.itemDesc == ''
        case ItemCategoryTypes.SUBL:
          return item.itemDesc == ''
        default:
          throw 'Unknown type"' + type + '"'
      }
    },
    determineMethodFromType(type) {
      switch (type) {
        case ItemCategoryTypes.FIBER:
        case ItemCategoryTypes.CRUSH:
        case ItemCategoryTypes.CD:
        case ItemCategoryTypes.MECH:
          return {
            method: 'Labour',
            list: 'others'
          }
        case ItemCategoryTypes.RR:
        case ItemCategoryTypes.RWA:
        case ItemCategoryTypes.REP:
        case ItemCategoryTypes.PAINT:
          return {
            method: 'Labour',
            list: 'labours'
          }
        case ItemCategoryTypes.PART:
          return {
            method: 'Part',
            list: 'parts'
          }
        case ItemCategoryTypes.OPG:
          return {
            method: 'OPG',
            list: 'opgs'
          }
        case ItemCategoryTypes.MISC:
          return {
            method: 'Misc',
            list: 'miscs'
          }
        case ItemCategoryTypes.SUBL:
          return {
            method: 'Sublet',
            list: 'sublets'
          }
        default:
          throw 'Unknown type"' + type + '"'
      }
    },
    determineValueFromType(type, item) {
      switch (type) {
        case ItemCategoryTypes.RR:
          return item.rrHour
        case ItemCategoryTypes.RWA:
          return item.rwaHour
        case ItemCategoryTypes.REP:
          return item.repairHour
        case ItemCategoryTypes.PAINT:
          return item.paintHour
        case ItemCategoryTypes.FIBER:
          return item.fgHour
        case ItemCategoryTypes.CRUSH:
          return item.crushHour
        case ItemCategoryTypes.CD:
          return item.cdHour
        case ItemCategoryTypes.MECH:
          return item.mechHour
        default:
          throw 'Unknown type "' + type + '"'
      }
    },
    copyToType(type, item) {
      let methodType = this.determineMethodFromType(type)
      let newItem = this['addBlankItem' + methodType.method](type)
      newItem = _merge(newItem, item)
      newItem.quoteItemId = Guid.newGuid()
      newItem.itemType = type
      newItem.mark = ''
      newItem.isNew = true
      // DO CLEANUP??????

      // add default qty for parts
      if (type === ItemCategoryTypes.PART) {
        newItem.itemQuantity = 1
      }

      this.innerValue[methodType.list].splice(this.innerValue[methodType.list].length, 1, newItem)

      this.innerValue.lines = this.nextLineNumber + 1
      this.$toast.open({
        message: 'Item Copied',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })
    },
    addBlankItem(type) {
      let methodType = this.determineMethodFromType(type)

      const newItem = this['addBlankItem' + methodType.method](type)
      newItem.itemNo = '0001'

      this.$eventHub.$emit(EventHubTypes.lastAddedItem, newItem)

      this.innerValue[methodType.list].splice(this.innerValue[methodType.list].length, 1, newItem)

      this.innerValue.lines = this.nextLineNumber + 1
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })

      this.$nextTick(() => {
        this.$root.$emit('scroll-to-item', { item: newItem, action: 'addBlank' })
      })

      return this.innerValue[methodType.list][this.innerValue[methodType.list].length]
    },
    findItem(item, type, listType) {
      console.log(this.innerValue, listType)
      const existingItem = this.innerValue[listType].find(
        (i) => i.itemDesc === item.description && i.itemNo === item.itemNo && !i.deleted && i.itemType === type && !i.deleteOtherItem
      )
      return existingItem
    },
    addNewItems(item, category, action) {
      // THIS IS REQUIRED TO DEAL WITH THE COMPOSITE TABS
      if (category == 'labours') {
        if (item.rrHour !== 0) {
          this.addNewItem(ItemCategoryTypes.RR, item, action)
        }
        if (item.rwaHour !== 0) {
          this.addNewItem(ItemCategoryTypes.RWA, item, action)
        }
        if (item.repairHour !== 0) {
          this.addNewItem(ItemCategoryTypes.REP, item, action)
        }
        if (item.paintHour !== 0) {
          this.addNewItem(ItemCategoryTypes.PAINT, item, action)
        }
      } else if (category == 'others') {
        if (item.fgHour !== 0) {
          this.addNewItem(ItemCategoryTypes.FIBER, item, action)
        }
        if (item.crushHour !== 0) {
          this.addNewItem(ItemCategoryTypes.CRUSH, item, action)
        }
        if (item.cdHour !== 0) {
          this.addNewItem(ItemCategoryTypes.CD, item, action)
        }
        if (item.mechHour !== 0) {
          this.addNewItem(ItemCategoryTypes.MECH, item, action)
        }
      }
    },
    addNewItem(type, item, action) {
      console.log('addNewItem', action)
      if (this.lastAddedItem !== null && this.isBlankItem(this.lastAddedItem) && this.lastAddedItem.itemType == type) {
        this.deleteItem(this.lastAddedItem)
      }

      let methodType = this.determineMethodFromType(type)
      let existingItem = this.findItem(item, type, methodType.list)
      if (!existingItem) {
        const newItem = this['addItem' + methodType.method](type, item)

        let temp = _cloneDeep(this.selectedButterflyGroupsList)
        temp.map((i) => {
          if (i.isSelected) {
            if (i.isDone === undefined) {
              i.isDone = 0
            }

            i.isDone += 1
          }
        })
        this.selectedButterflyGroupsList = temp

        this.$eventHub.$emit(EventHubTypes.lastAddedItem, newItem)

        this['onItemAdded' + methodType.method]([newItem])
        this.$nextTick(() => {
          this.$root.$emit('scroll-to-item', { item: newItem, action: action })
        })
      } else {
        let temp = _cloneDeep(this.selectedButterflyGroupsList)
        temp.map((i) => {
          if (i.isSelected) {
            if (i.isDone === undefined) {
              i.isDone = 0
            }

            i.isDone -= 1
          }
        })
        this.selectedButterflyGroupsList = temp

        this.deleteItem(existingItem)
        //this.$notification.openNotificationWithType('warning', methodType.method, `${type} item is already in selected list. Item not added.`)
      }
    },
    onItemAddedGeneric(newItems) {
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''

      if (!_isEmpty(newItems)) {
        const vm = this
        newItems.forEach(function (item) {
          vm.innerValue.lines = item.lineNumber + 1
          let methodType = vm.determineMethodFromType(item.itemType)
          vm.innerValue[methodType.list].splice(vm.innerValue[methodType.list].length, 1, item)
        })
      }

      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })
    },

    // TYPES
    // LABOUR / OTHER LABOUR
    addBlankItemLabour(type) {
      return this.addItemLabour(type, { itemNo: '', description: '', hours: 0 })
    },
    addItemLabour(type, item) {
      let rate = 0
      switch (type) {
        case ItemCategoryTypes.FIBER:
        case ItemCategoryTypes.CRUSH:
        case ItemCategoryTypes.CD:
        case ItemCategoryTypes.MECH:
          rate = this.isNtar ? this.innerValue.shopRate : this.getLabourRate(type).rate
        case ItemCategoryTypes.RR:
        case ItemCategoryTypes.RWA:
        case ItemCategoryTypes.REP:
          rate = this.isNtar ? this.innerValue.shopRate : this.getLabourRate(type).rate
          break
        case ItemCategoryTypes.PAINT:
          rate = this.isNtar ? this.innerValue.shopRate : this.getLabourRate(this.vehicle.paintGroup).rate
          break
      }

      let newItem = new QuoteItemModel(this.quoteId, item.itemNo, item.description, type)

      newItem.lineNumber = this.getNextLineNumber(type)

      //// item.hour value comes from template, this.determineValueFromType(type, item) comes from butterfly selection
      let hours = item.hourValue || this.determineValueFromType(type, item) || 0

      newItem.hourValue = this.innerValue.quotingMethod === QuotingMethodTypes.Hour ? hours : roundAwayFromZero(hours * rate)
      newItem.rate = rate
      newItem.dollarValue = roundAwayFromZero(hours * rate)
      newItem.opgCode = this.innerValue.vehiclePaintGroup
      newItem.sortNo = this.getSortIndex(type)
      console.log(newItem)
      return newItem
    },
    onItemAddedLabour(newItems) {
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''

      if (!_isEmpty(newItems)) {
        const vm = this
        newItems.forEach(function (item) {
          let methodType = vm.determineMethodFromType(item.itemType)

          vm.innerValue[methodType.list].splice(vm.innerValue[methodType.list].length, 1, item)
          if (vm.isNtar && item.hourValue > 0 && (item.itemType === ItemCategoryTypes.REP || item.itemType === ItemCategoryTypes.PAINT)) {
            vm.addUpdateRepairPaintLoading(item)
          }
        })
        this.innerValue.lines = Math.max(...newItems.map((i) => i.lineNumber), 0) + 1
        // this.items = _orderBy(this.innerValue.labours.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
        this.$toast.open({
          message: `Item${newItems.length > 1 ? 's' : ''} added`,
          type: 'is-success',
          position: 'is-bottom',
          queue: false
        })
      }
    },

    // PARTS
    addBlankItemPart(type) {
      return this.addItemPart(type, { itemNo: '', description: '', quantity: 1, unitPrice: 0, tradePrice: 0, partNo: '', side: 'L' })
    },
    addItemPart(type, item) {
      let newItem = new QuoteItemModel(this.quoteId, item.itemNo, item.description, type)
      newItem.lineNumber = this.getNextLineNumber(type)
      newItem.itemQuantity = item.quantity
      newItem.value = roundAwayFromZero(item.quantity * item.unitPrice)
      newItem.mark = PartTypes.NEW.value
      newItem.markupPercent = 0
      newItem.markupValue = roundAwayFromZero(item.quantity * item.unitPrice)
      newItem.buyPrice = item.tradePrice
      newItem.partNo = item.partNo
      newItem.side = item.side
      newItem.sortNo = this.getSortIndex(type)
      if (this.innerValue.isAudanet) {
        newItem.rev = QuoteItemRevTypes.NonAudaNetPart
      }
      return newItem
    },
    onItemAddedPart(newItem) {
      return this.onItemAddedGeneric(newItem)
    },

    // OPG
    addBlankItemOPG(type) {
      return this.addItemOPG(type, { itemNo: '', description: '' })
    },
    addItemOPG(type, item) {
      let newItem = new QuoteItemModel(this.quoteId, item.itemNo, item.description, type)
      newItem.lineNumber = this.getNextLineNumber(type)
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.hourValue = 0
      newItem.rate = this.innerValue.rates.find((r) => r.labourCodeId === this.vehicle.paintGroup).rate
      newItem.dollarValue = 0
      newItem.opgCode = this.vehicle.paintGroup
      newItem.sortNo = this.getSortIndex(type)

      return newItem
    },
    onItemAddedOPG(newItem) {
      return this.onItemAddedGeneric(newItem)
    },

    // MISC
    addBlankItemMisc(type) {
      return this.addItemMisc(type, { itemNo: '', description: '', price: 0, side: '' })
    },
    addItemMisc(type, item) {
      let newItem = new QuoteItemModel(this.quoteId, item.itemNo, item.description, type)
      newItem.lineNumber = this.getNextLineNumber(type)
      newItem.value = item.price
      newItem.side = item.side
      newItem.sortNo = this.getSortIndex(type)

      return newItem
    },
    onItemAddedMisc(newItem) {
      return this.onItemAddedGeneric(newItem)
    },

    // SUBLET
    addBlankItemSublet(type) {
      return this.addItemSublet(type, { itemNo: '', description: '', price: 0 })
    },
    addItemSublet(type, item) {
      let newItem = new QuoteItemModel(this.quoteId, item.itemNo, item.description, type)
      newItem.lineNumber = this.getNextLineNumber(type)
      newItem.value = item.price
      newItem.sortNo = this.getSortIndex(type)

      return newItem
    },
    onItemAddedSublet(newItem) {
      return this.onItemAddedGeneric(newItem)
    },

    getSortIndex(type) {
      let methodType = this.determineMethodFromType(type)
      return this.innerValue[methodType.list].length ? Math.max(...this.innerValue[methodType.list].map((i) => i.sortNo)) + 1 : 1
    },
    getNextLineNumber(type) {
      let methodType = this.determineMethodFromType(type)

      let newLineNumber = Math.max(
        this.innerValue[methodType.list].reduce((max, row) => {
          return row.lineNumber > max ? row.lineNumber : max
        }, 0) + 1,
        0
      )

      return !this.innerValue.isAudanet ? newLineNumber : 0
    },
    updateValue(index, item, key = null, markupPercent = 0) {

      const itemTypes = {
        RR: 'Labour',
        RWA: 'Labour',
        REP: 'Labour',
        PAINT: 'Paint',
        PART: 'Part',
        MECH: 'Others',
        FIBER: 'Others',
        CRUSH: 'Others',
        CD: 'Others',
        MISC: 'Misc',
        SUBL: 'Sublet'
      }

      var itemType = itemTypes[item.itemType]

      if (itemType == 'Labour') {
        if (this.innerValue.quotingMethod === QuotingMethodTypes.Dollar) {
          item.dollarValue = item.hourValue
        } else {
          item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
          if (this.getLabourRateEx(item).rate === item.rate) {
            item.rev = QuoteItemRevTypes.Normal
          } else {
            item.rev = QuoteItemRevTypes.SpecRate
          }
        }
        this.addUpdateRepairPaintLoading(item)
      } else if (itemType == 'Others') {
        if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
          item.dollarValue = item.hourValue
        } else {
          item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
        }
      } else if (itemType == 'Part') {
        if (item && !item.deleteOtherItem && key !== null) {
          if (key === 'markup') {
          item.value = roundAwayFromZero(item.markupValue / (1 + markupPercent / 100))
          } else if (key === 'value') {
            item.markupValue = roundAwayFromZero(item.value * (1 + markupPercent / 100))
          } else if (key === 'type'){
            // different types have different markups, use value as the baseline
            item.markupValue =  roundAwayFromZero(item.value * (1 + markupPercent / 100))
          }
          }
      } else if (itemType == 'Paint') {
        // is there a need for finding the item again?
        // const itemToUpdate = this.innerValue.labours.find((i) => i.quoteItemId === item.quoteItemId)

        if (item.itemType === ItemCategoryTypes.PAINT && this.isNtar && key === 'blend' ) {
          const value = item.blend ? roundAwayFromZero(item.hourValue * 0.7) : roundAwayFromZero(item.hourValue / 0.7)
          item.hourValue = value
        }

        if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
          item.dollarValue = item.hourValue
        } else {
          item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
        }
      } else if (itemType == 'Misc') {
        // DO NOTHING
      } else if (itemType == 'Sublet') {
        if (item && item.deleteOtherItem && item.value > 0) {
          item.value = item.value * (item.itemQuantity !== 0 ? item.itemQuantity : 1)
        }
      }

      this.setItemStatus(item)
      this.updateTotal()
    },
    async getDefaultPaintItem(itemNo) {
      const filter = {
        countryId: Guid.empty(),
        companyId: this.value.companyId,
        query: itemNo,
        butterflyCode: '',
        scheduleId: this.vehicle.scheduleId || '',
        bodyId: this.vehicle.bodyId || '',
        bodySize: '',
        paintGroup: this.vehicle.paintGroup || '',
        variantId: 0,
        nvic: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 1
      }
      const paintItem = await QuoteService.getAvailableLabourItems(filter, this.value.labourType.toLowerCase())
      return paintItem
    },
    async addUpdateRepairPaintLoading(item, isManualLoading = false) {
      let repairItem =
        item.itemType === ItemCategoryTypes.REP
          ? this.innerValue.labours.find((i) => i.quoteItemId === item.quoteItemId && !i.deleted)
          : this.innerValue.labours.find((i) => i.itemType === ItemCategoryTypes.REP && i.itemNo === item.itemNo && i.itemDesc === item.itemDesc && !i.deleted)
      // console.log(repairItem)
      if (!this.isNtar || !repairItem || !this.ntarLoadingItems || !this.ntarOtherLoadingItems || !this.ntarLoadingValues || !this.quoteInsurer) {
        return
      }

      const linkedItem = this.innerValue.labours.find((i) => i.linkedItemId === repairItem.quoteItemId && i.isLoadItem && !i.deleted)
      let ntarLoadingItem = null
      if (this.innerValue.labourType === LabourTimeTypes.eMTA) {
        ntarLoadingItem = this.ntarOtherLoadingItems.find((n) => n.mtaItemDescription === repairItem.itemDesc && n.category === 'RA')
      } else {
        ntarLoadingItem = this.ntarLoadingItems.find((n) => n.itemNo === repairItem.itemNo && n.category === 'RA')
      }
      const hasPaintLoading =
        (ntarLoadingItem || isManualLoading) && (repairItem.itemType === ItemCategoryTypes.REP || repairItem.itemType === ItemCategoryTypes.PAINT)
      if (!hasPaintLoading && !linkedItem) {
        // console.log('!hasPaintLoading', repairItem.itemNo, ntarLoadingItem)
        return
      } else if (linkedItem && (repairItem.hourValue === 0 || repairItem.reportOnly)) {
        // Delete paint loading
        // console.log('Delete paint loading')
        const index = this.innerValue.labours.findIndex((i) => i.quoteItemId === linkedItem.quoteItemId)
        this.deleteItem(linkedItem, index)
        repairItem.loadingPercent = 0
        return
      }
      let paintItem =
        item.itemType === ItemCategoryTypes.PAINT
          ? this.innerValue.labours.find((i) => i.quoteItemId === item.quoteItemId && !i.deleted)
          : this.innerValue.labours.find(
              (i) => i.itemType === ItemCategoryTypes.PAINT && i.itemNo === repairItem.itemNo && i.itemDesc === repairItem.itemDesc && !i.deleted
            )
      console.log('find paintItem', paintItem)
      let paintHour = 0
      if (!paintItem) {
        const paintItems = await this.getDefaultPaintItem(repairItem.itemNo)
        paintItem = paintItems[0]
        console.log('!paintItem', paintItem)
        paintHour = paintItem.paintHour
      } else {
        paintHour = paintItem.hourValue
        console.log('found paintItem', paintItem)
      }
      const paintLoadingItemNo = (parseInt(repairItem.itemNo, 10) + 5000).toString()
      let paintLoadingItem =
        linkedItem ||
        this.innerValue.labours.find((p) => p.itemType === ItemCategoryTypes.PAINT && p.itemNo === paintLoadingItemNo && p.isLoadItem && !p.deleted)
      let isNew = false
      const loadingValue = this.quoteInsurer.insurerLoadingValues.find(
        (v) => v.category === 'RA' && repairItem.hourValue >= v.lowerThreshold && repairItem.hourValue <= v.upperThreshold
      )
      const loadingPercent = (!loadingValue ? 0 : loadingValue.value) * 100
      if (!isManualLoading) {
        repairItem.loadingPercent = loadingPercent
      } else if (isManualLoading && loadingPercent > repairItem.loadingPercent) {
        repairItem.loadingPercent = loadingPercent
      }
      // console.log(repairItem.itemNo, ntarLoadingItem, paintItem, paintLoadingItem)
      // Cannot find existing loading item then create new
      // console.log(paintLoadingItemNo, repairItem.loadingPercent, paintLoadingItem.isNew)
      if (!paintLoadingItem) {
        paintLoadingItem = new QuoteItemModel(this.value.quoteId, paintLoadingItemNo, `${repairItem.itemDesc} (Paint Loading)`, ItemCategoryTypes.PAINT)
        paintLoadingItem.sortNo = this.value.length ? Math.max(...this.value.map((i) => i.sortNo)) + 1 : 1
        isNew = true
      } else if (loadingPercent > repairItem.loadingPercent) {
        // lll
      }
      paintLoadingItem.lineNumber = this.nextLineNumber
      paintLoadingItem.isLoadItem = true
      paintLoadingItem.linkedItemId = repairItem.quoteItemId
      paintLoadingItem.hourValue = roundAwayFromZero((paintHour * repairItem.loadingPercent) / 100)
      paintLoadingItem.rate = this.value.shopRate
      paintLoadingItem.dollarValue = roundAwayFromZero(paintLoadingItem.hourValue * this.value.shopRate)
      repairItem.linkedItemId = paintLoadingItem.quoteItemId
      // console.log(paintLoadingItemNo, repairItem.loadingPercent, paintLoadingItem.isNew)
      if (isNew) {
        this.innerValue.labours.splice(this.innerValue.labours.length, 1, paintLoadingItem)
        this.innerValue.lines = paintLoadingItem.lineNumber + 1
        // this.items = _orderBy(this.innerValue.labours.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
      }
    },
    getLabourRate(code) {
      if (!this.innerValue.rates) {
        console.log('here')
        return {}

      }

      const isPaintType = Object.values(PaintGroupTypes).some((p) => p === code)
      const codeType = isPaintType ? ItemCategoryTypes.PAINT : code
      let rate = this.isNtar ? this.innerValue.rates[0] : this.innerValue.rates.find((i) => i.labourCodeId === code)
      if (!rate) {
        const labourType = this.$labourTypes.find((t) => t.labourTypeCode === codeType)
        const newRate = {
          quoteId: this.innerValue.quoteId,
          labourCodeId: code,
          labourTypeId: labourType?.labourTypeId,
          rate: this.isNtar ? this.innerValue.shopRate : 0,
          modifiedBy: '',
          modifiedDate: null,
          createdBy: '',
          createdDate: null,
          isNew: true,
          quoteVersion: 0,
          deleted: false
        }
        this.innerValue.rates.push(newRate)
        rate = newRate
      }
      return rate
    },
    getLabourRateEx(item) {
      if (!this.innerValue.rates && this.vehicle) {
        return {}
      }
      const code = item.itemType !== ItemCategoryTypes.PAINT ? item.itemType : this.vehicle ? this.vehicle.paintGroup : 'S1'
      return this.getLabourRate(code)
    },
    getTabNameFromType(type) {
      for (let name of Object.keys(this.itemTypeTabs)) {
        let itemTab = this.itemTypeTabs[name]
        if (itemTab.itemType instanceof Function) {
          if (itemTab.itemType(type)) {
            return name
          }
        } else {
          if (type == itemTab.itemType) {
            return name
          }
        }
      }
      throw 'getTabNameFromType Unknown type "' + type + '"'
    },
    getTabFromType(type) {
      let name = this.getTabNameFromType(type)
      return this.itemTypeTabs[name]
    },
    getTabFromItem(item) {
      return this.getTabFromType(item.itemType)
    },
    isSource(type, item) {
      let tab = this.getTabFromItem(item)
      if (Array.isArray(type)) {
        return type.indexOf(tab.itemSource) !== -1
      }

      return type === tab.itemSource
    },
    isType(type, item) {
      if (Array.isArray(type)) {
        return type.indexOf(item.itemType) !== -1
      }

      return type === item.itemType
    },
    deleteItem(item, index) {

      console.log('deleteing here')

      let itemSource = this.getTabFromItem(item).itemSource
      // let originalItemStatus = item.itemStatus

      this.deletePaintLoading(item)
      const deletedItem = _cloneDeep(item)
      if (item.isNew) {
        const itemIndex = this.innerValue[itemSource]
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue[itemSource].splice(itemIndex, 1)
          // this.items.splice(index, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
      }
      this.addUpdateRepairPaintLoading(deletedItem)
      this.updateTotal()

      // this.$snackbar.open({
      //   message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
      //   type: 'is-danger',
      //   position: 'is-bottom',
      //   actionText: 'Undo',
      //   onAction: () => {
      //     if (item.isNew) {
      //       this.innerValue[itemSource].splice(this.innerValue[itemSource].length, 1, item)
      //       // this.items.splice(this.items.length, 1, item)
      //     } else {
      //       item.deleted = false
      //       item.isDeleted = false
      //       item.itemStatus = originalItemStatus
      //     }
      //     this.updateTotal()
      //   }
      // })
    },
    deletePaintLoading(item) {
      let itemSource = this.getTabFromItem(item).itemSource
      if (this.isNtar && Guid.validGuid(item.linkedItemId)) {
        const linkedItem = this.innerValue[itemSource].find((i) => i.quoteItemId === item.linkedItemId && i.isLoadItem && !i.deleted)
        const index = this.innerValue[itemSource].findIndex((i) => i.quoteItemId === item.linkedItemId && i.isLoadItem && !i.deleted)
        //console.log(linkedItem)
        if (linkedItem) {
          this.deleteItem(linkedItem, index)
        }
      }
    }
  }
}
