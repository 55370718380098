import { AvailableMiscTypes, AvailableSubletTypes } from './../QuoteService'
import { LabourTimeTypes, ItemCategoryTypes, QuotingMethodTypes } from '@/enums'
import QuoteService from './../QuoteService'

export default {
  mixins: [],
  computed: {

  },
  methods: {
    getTypeDisplayValue(type, item) {
      switch (type) {
        case ItemCategoryTypes.RR:
          return item.rrHour
        case ItemCategoryTypes.RWA:
          return item.rwaHour
        case ItemCategoryTypes.REP:
          return item.repairHour
        case ItemCategoryTypes.PAINT:
          return item.paintHour
        case ItemCategoryTypes.FIBER:
          return item.fgHour
        case ItemCategoryTypes.CRUSH:
          return item.crushHour
        case ItemCategoryTypes.CD:
          return item.cdHour
        case ItemCategoryTypes.MECH:
          return item.mechHour
        case ItemCategoryTypes.PART:
          return item.quantity
        case ItemCategoryTypes.SUBL:
        case ItemCategoryTypes.MISC:
          return item.price
        default:
          throw 'getTypeDisplayValue Unknown type "'+type+'"'
      }
    },
    getDisplayValue(type, item) {
      let value = this.getTypeDisplayValue(type, item)
      let rate = 0
      switch (type) {
        case ItemCategoryTypes.RR:
          rate = this.isNtar ? this.shopRate : this.getLabourRate(ItemCategoryTypes.RR).rate
          break
        case ItemCategoryTypes.RWA:
          rate = this.isNtar ? this.shopRate : this.getLabourRate(ItemCategoryTypes.RWA).rate
          break
        case ItemCategoryTypes.REP:
          rate = this.isNtar ? this.shopRate : this.getLabourRate(ItemCategoryTypes.REP).rate
          break
        case ItemCategoryTypes.PAINT:
          rate = this.isNtar ? this.shopRate : this.getLabourRate(this.vehicle.paintGroup).rate
          break
        case ItemCategoryTypes.FIBER:
          rate = this.isNtar ? this.shopRate : this.getLabourRate(ItemCategoryTypes.FIBER).rate
          break
        case ItemCategoryTypes.CRUSH:
          rate = this.isNtar ? this.shopRate : this.getLabourRate(ItemCategoryTypes.CRUSH).rate
          break
        case ItemCategoryTypes.CD:
          rate = this.isNtar ? this.shopRate : this.getLabourRate(ItemCategoryTypes.CD).rate
          break
        case ItemCategoryTypes.MECH:
          rate = this.isNtar ? this.shopRate : this.getLabourRate(ItemCategoryTypes.MECH).rate
          break
      }
      return this.quotingMethod === QuotingMethodTypes.Dollar ? roundAwayFromZero(rate * value) : value
    },
    async getAvailableLabours(searchAll = false) {
      // search all is only true when random search is done

      // return empty array if no time type is selected
      if (!this.selectedTimeType) return []

      let registered = true
      if (this.selectedTimeType === this.labourTimeTypes.RACQ.replace('-', '').toLowerCase()) {
        this.filter.bodySize = this.selectedRacqBodySize
      } else if (this.selectedTimeType === this.labourTimeTypes.WESF.replace('-', '').toLowerCase()) {
        this.filter.bodySize = this.selectedWesfBodySize
      } else if (this.selectedTimeType === this.labourTimeTypes.eMTA.replace('-', '').toLowerCase()) {
        registered = await this.isEmtaRegistered()
        if (!this.validEmtaVehicle && registered) {
          this.isEmtaVehicleSelectionModalActive = true
          return
        } else if (this.validEmtaVehicle && registered) {
          this.filter.nvic = this.emtaVehicle.nvic
          this.filter.variantId = this.emtaVehicle.variantId
          if (!this.filter.scheduleId) {
            this.filter.scheduleId = `U${this.emtaVehicle.variantId.slice(-3)}`
          }
          this.isAvailableItemsLoading = true
          const response = await EmtaService.updateEmtaCatalogue(this.filter)
          this.isAvailableItemsLoading = false
          console.log(response)
          /// WHY??????
        }
      }

      console.log(this.filter, this.selectedTimeType)

      let availableItems = []
      if (registered) {
        if (this.filter.butterflyCode !== '' || !!searchAll) {
          this.isAvailableItemsLoading = true

          console.log('here inside gettin labour items')

          availableItems = await QuoteService.getAvailableLabourItems(this.filter, this.selectedTimeType)
          this.isAvailableItemsLoading = false
        }
      } else {
        this.isEmtaRegistrationModalActive = true
      }

      return availableItems
    },
    async getAvailableParts() {
      this.isAvailableItemsLoading = true
      let availableItems = await QuoteService.getAvailableParts(this.filter)
      this.isAvailableItemsLoading = false
      return availableItems
    },
    async getAvailableOpgs() {
      this.isAvailableItemsLoading = true
      let availableItems = await QuoteService.getAvailableOpgItems(this.filter)
      this.isAvailableItemsLoading = false
      return availableItems
    },
    async getAvailableOthers() {
      this.isAvailableItemsLoading = true
      let availableItems = await QuoteService.getAvailableOtherLabourItems(this.filter)
      this.isAvailableItemsLoading = false
      return availableItems
    },
    async getAvailableMiscs() {
      this.isAvailableItemsLoading = true
      let activeTab = this.innerValue.labourType === LabourTimeTypes.eMTA ? AvailableMiscTypes.emta : AvailableMiscTypes.standard
      let availableItems = await QuoteService.getAvailableMiscItems(this.filter, activeTab)
      this.isAvailableItemsLoading = false
      return availableItems
    },
    async getAvailableSublets() {
      this.isAvailableItemsLoading = true
      // THERES A THING ABOUT NZ IN AvailableSublets
      let activeTab = AvailableSubletTypes.standard
      let availableItems = await QuoteService.getAvailableSubletItems(this.filter, activeTab)
      this.isAvailableItemsLoading = false
      return availableItems
    }
  }
}
