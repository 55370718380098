import {
  // LabourColumns,
  // OtherLabourColumns,
  // PartColumns,
  // MiscColumns,
  // SubletColumns,
  // OpgColumns
  QuoteSelectionsLabourColumns,
  QuoteSelectionsOtherLabourColumns,
  QuoteSelectionsPartColumns,
  QuoteSelectionsMiscColumns,
  QuoteSelectionsSubletColumns,
  QuoteSelectionsOpgColumns
} from './../columns'
import {
  //AvailableLabourColumns,
  //AvailableOtherLabourColumns,
  //AvailablePartsColumns,
  //AvailableMiscColumns,
  //AvailableSubletColumns,
  //AvailableOpgColumns,
  QuoteSelectionsAvailableLabourColumns,
  QuoteSelectionsAvailablePartsColumns,
  QuoteSelectionsAvailableMiscSubletColumns,
  QuoteSelectionsAvailableOtherLabourColumns,
  QuoteSelectionsAvailableOpgColumns
} from './../columns'
import _cloneDeep from 'lodash/cloneDeep'
import { QuotingMethodTypes } from '@/enums'
export default {
  mixins: [],
  computed: {
    availableItemsColumns() {
      const columns = {}
      /*
      columns.labourColumns = AvailableLabourColumns
      columns.otherLabourColumns = AvailableOtherLabourColumns
      columns.partColumns = QuoteSelectionsAvailablePartsColumns
      columns.miscColumns = AvailableMiscColumns
      columns.subletColumns = AvailableSubletColumns
      columns.opgColumns = AvailableOpgColumns
      */

      columns.labourColumns = QuoteSelectionsAvailableLabourColumns
      columns.otherLabourColumns = QuoteSelectionsAvailableOtherLabourColumns
      columns.partColumns = QuoteSelectionsAvailablePartsColumns
      columns.miscColumns = QuoteSelectionsAvailableMiscSubletColumns
      columns.subletColumns = QuoteSelectionsAvailableMiscSubletColumns
      columns.opgColumns = QuoteSelectionsAvailableOpgColumns

      return columns
    },
    columns() {
      const columns = {}
      columns.labourColumns = this.labourColumns
      columns.otherLabourColumns = this.otherLabourColumns
      columns.partColumns = this.partColumns
      columns.miscColumns = this.miscColumns
      columns.subletColumns = this.subletColumns
      columns.opgColumns = this.opgColumns
      return columns
    },
    labourColumns() {
      const c = _cloneDeep(QuoteSelectionsLabourColumns)
      if (this.innerValue.quotingMethod === QuotingMethodTypes.Dollar) {
        //c[2].title = 'Amount'
      } else if (this.innerValue.quotingMethod === QuotingMethodTypes.Hour) {
        //c[2].title = QuoteSelectionsLabourColumns[1].title
      }
      return c
    },
    otherLabourColumns() {
      const c = _cloneDeep(QuoteSelectionsOtherLabourColumns)
      if (this.innerValue.quotingMethod === QuotingMethodTypes.Dollar) {
        //c[2].title = 'Amount'
      } else if (this.innerValue.quotingMethod === QuotingMethodTypes.Hour) {
        //c[2].title = QuoteSelectionsOtherLabourColumns[1].title
      }
      return c
    },
    opgColumns() {
      const c = _cloneDeep(QuoteSelectionsOpgColumns)
      if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
        //c[2].title = 'Amount'
      } else if (this.innerValue.quotingMethod === QuotingMethodTypes.Hour) {
        //c[2].title = QuoteSelectionsOpgColumns[1].title
      }
      return c
    },
    partColumns() {
      const c = QuoteSelectionsPartColumns
      return c
    },
    miscColumns() {
      const c = QuoteSelectionsMiscColumns
      return c
    },
    subletColumns() {
      const c = QuoteSelectionsSubletColumns
      return c
    }
  },
  methods: {}
}
