import QuoteRoutes from '../route-types'

export default {
  inject: ['$vv'],
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    validationItems() {
      return this.getValidationItems()
    }
  },
  methods: {
    getValidationItems(route = null) {
      if(route === null) {
        route = this.$route.name
      }

      switch (route) {
        case QuoteRoutes.QuoteLabour.name:
          return this.$v.entity.labours
        case QuoteRoutes.QuoteOtherLabour.name:
          return this.$v.entity.others
        case QuoteRoutes.QuotePart.name:
          return this.$v.entity.parts
        case QuoteRoutes.QuoteOpg.name:
          return this.$v.entity.opgs
        case QuoteRoutes.QuoteMisc.name:
          return this.$v.entity.miscs
        case QuoteRoutes.QuoteSublet.name:
          return this.$v.entity.sublets
        default:
          break
      }
    },
    isValidItemNo(quoteItemId, route=null) {
      if(Array.isArray(route)) {
        for(var r of route) {
          let index = this.getValidationItems(r)?.$model?.findIndex(i => i.quoteItemId === quoteItemId)
          if(index != -1 && index != null) {
            return this.isValidItemNo(quoteItemId,r)
          }
        }
        return false
      }

      let validationItems = this.getValidationItems(route)
      if (validationItems) {
        const index = validationItems.$model.findIndex(i => i.quoteItemId === quoteItemId)
        if (validationItems.$each[index]) {
          return validationItems.$each[index].itemNo.required && !validationItems.$each[index].itemNo.$anyError
        } else {
          return false
        }
      }
    },
    isValidItemDesc(quoteItemId, route=null) {
      if(route !== null) {
        if(Array.isArray(route)) {
          for(var r of route) {
            let index = this.getValidationItems(r)?.$model?.findIndex(i => i.quoteItemId === quoteItemId)
            if(index != -1 && index != null) {
              return this.isValidItemNo(quoteItemId,r)
            }
          }
          return false
        }
      }

      let validationItems = this.getValidationItems(route)
      if (validationItems) {
        const index = validationItems.$model.findIndex(i => i.quoteItemId === quoteItemId)
        if (validationItems.$each[index]) {
          return validationItems.$each[index].itemDesc.required && !validationItems.$each[index].itemDesc.$anyError
        } else {
          return false
        }
      }
    }
  }
}
